import { React, useContext } from 'react';
import './App.css';
import { HomePage } from './pages/HomePage';
import { Used } from './pages/Used';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Single from './pages/single/Single';

import List from './pages/list/List';
import New from './pages/new/New';
import { userInputs } from './formSource';
import { AuthContext } from './context/AuthContext';
import { Sell } from './pages/Sell';
import { ConsultedCar } from './pages/ConsultedCar';

const RequireAuth = ({ children }) => {
    const { currentUser } = useContext(AuthContext);

    return currentUser !== null ? children : <Navigate to="/admin/login" replace />;
};

function App() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/admin/login" element={<Login />} />
                    <Route
                        path="/admin/"
                        element={
                            <RequireAuth>
                                <Home />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/admin/users"
                        element={
                            <RequireAuth>
                                <List />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/admin/users/:userId"
                        element={
                            <RequireAuth>
                                <Single />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/admin/users/new"
                        element={
                            <RequireAuth>
                                <New inputs={userInputs} title="Anadir nuevo Auto" />
                            </RequireAuth>
                        }
                    />
                </Routes>

                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/used" element={<Used />} />
                    <Route path="/sell" element={<Sell />} />
                    <Route path="/singlecarconsult/:carid" element={<ConsultedCar />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
