import React, { useState, useEffect, useRef } from 'react';
import styles from '../styles/Navbar.module.css';
import logoGrande from '../assets/logoGrande.png';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';

export function Navbar() {
    const [isScrolled, setIsScrolled] = useState(false);
    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const navRef = useRef();

    useEffect(() => {
        const handleScroll = () => {
            const scrolled = window.scrollY > 0;
            setIsScrolled(scrolled);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleMenuToggle = () => {
        setMobileNavOpen(!mobileNavOpen);
    };

    return (
        <>
            <div className={`${styles.bigContainerNavbar} ${isScrolled ? styles.fixed : ''}`}>
                <div className={styles.container}>
                    <Link to={'/'}>
                        <img className={styles.img} src={logoGrande} alt="LogoPrincipal"></img>
                    </Link>
                    <nav className={styles.categorias}>
                        <a href="/" className={styles.categoria}>
                            Inicio
                        </a>
                        <a href="/used" className={styles.categoria}>
                            Usados
                        </a>
                        <a href="/sell" className={styles.categoria}>
                            Vender
                        </a>
                    </nav>
                </div>
            </div>

            <div className={`${styles.bigContainerPhone} ${isScrolled ? styles.fixed : ''}`}>
                <nav className={styles.container}>
                    <img className={styles.img} src={logoGrande} alt="LogoPrincipal" />
                    <MenuIcon
                        sx={{ fontSize: 33 }}
                        className={styles.menuIcon}
                        onClick={handleMenuToggle}
                    />
                </nav>
                <div className={`${styles.mobileNav} ${mobileNavOpen ? styles.mobileNavOpen : ''}`}>
                    <nav className={styles.mobileNavContainer}>
                        <CloseIcon
                            sx={{ fontSize: 33 }}
                            className={styles.closeIcon}
                            onClick={handleMenuToggle}
                        />
                        <Link to="/" className={styles.mobileNavOption} onClick={handleMenuToggle}>
                            Inicio
                        </Link>
                        <Link
                            to="/used"
                            className={styles.mobileNavOption}
                            onClick={handleMenuToggle}
                        >
                            Usados
                        </Link>
                        <Link
                            to="/sell"
                            className={styles.mobileNavOption}
                            onClick={handleMenuToggle}
                        >
                            Vender
                        </Link>
                    </nav>
                </div>
            </div>
        </>
    );
}
