import './table.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const List = () => {
  const rows = [
    {
      id: 1143155,
      marca: 'Ferrari',
      img: 'https://cdn.motor1.com/images/mgl/P33WYL/s1/ferrari-sp48-unica.webp',
      patente: 'OWR918',
      version: '488',
      año: 2019,
      kilometraje: 9654,
    },
    {
      id: 2477900,
      marca: 'Lamborghini',
      img: 'https://www.autobild.es/sites/autobild.es/public/dc/fotos/Lamborghini_Aventador_SVJ_03.jpg',
      patente: 'AE419EZ',
      version: '321',
      año: 2022,
      kilometraje: 954,
    },
    {
      id: 5724713,
      marca: 'Mercedes Benz',
      img: 'https://www.topgear.com/sites/default/files/2021/11/Mercedes_C300D_0000.jpg',
      patente: 'MBC206',
      version: 'C-CLASS',
      año: 2018,
      kilometraje: 11047,
    },
    {
      id: 9173645,
      marca: 'Audi',
      img: 'https://cdn.motor1.com/images/mgl/W81Qr6/s1/el-nuevo-audi-a3-sportback.jpg',
      patente: 'AA931AE',
      version: 'A3',
      año: 2021,
      kilometraje: 1497,
    },
    {
      id: 7364532,
      marca: 'Honda',
      img: 'https://www.hondapilar.com.ar/wp-content/uploads/2018/09/Honda-FIT-PNG.jpg',
      patente: 'JTR651',
      version: 'FIT',
      año: 2010,
      kilometraje: 209324,
    },
  ];
  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">Tracking ID</TableCell>
            <TableCell className="tableCell">Product</TableCell>
            <TableCell className="tableCell">Marca</TableCell>
            <TableCell className="tableCell">Version</TableCell>
            <TableCell className="tableCell">Patente</TableCell>
            <TableCell className="tableCell">Año</TableCell>
            <TableCell className="tableCell">Kilometraje</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell className="tableCell">{row.id}</TableCell>
              <TableCell className="tableCell">
                <div className="cellWrapper">
                  <img src={row.img} alt="" className="image" />
                  {row.product}
                </div>
              </TableCell>
              <TableCell className="tableCell">{row.marca}</TableCell>
              <TableCell className="tableCell">{row.version}</TableCell>
              <TableCell className="tableCell">{row.patente}</TableCell>
              <TableCell className="tableCell">{row.año}</TableCell>
              <TableCell className="tableCell">{row.kilometraje}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;
