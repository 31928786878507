import React from 'react';
import './navbar.scss';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="items">
          <div className="item">
            <SettingsIcon className="icon" />
          </div>
          <div className="item">
            <DirectionsCarFilledIcon className="icon" />
          </div>
          <div className="item">
            <img
              src="https://i.ibb.co/FsR3JjH/logo-2019053013453214400.png"
              alt="profile"
              className="avatar"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
