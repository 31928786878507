import React, { useState, useEffect } from 'react';
import styles from '../../styles/Sell/List.module.css';
import motor from '../../assets/motor-de-busqueda-web.png';
import billete from '../../assets/billete-de-banco.png';
import conferencia from '../../assets/conferencia-online.png';
import 'react-phone-number-input/style.css';
import emailjs from 'emailjs-com';
import Spinner from './Spinner';

export const List = () => {
    const [showNewInfo, setShowNewInfo] = useState(false);
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleFormSubmit = (e) => {
        e.preventDefault();

        setIsSubmitting(true);

        emailjs
            .sendForm('service_xcfpoaj', 'template_dsp3q7a', e.target, 'FKo2iNv-iKMtyyVTE')
            .then(
                (result) => {
                    console.log(result.text);
                    alert('Correo enviado exitosamente');
                    window.location.reload();
                },
                (error) => {
                    console.log(error.text);
                    alert('Hubo un error al enviar el correo');
                }
            )
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const preventPasteNegative = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = parseFloat(clipboardData.getData('text'));

        if (pastedData < 0) {
            e.preventDefault();
        }
    };

    const handleNonNegativeInput = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
    };

    const [formData, setFormData] = useState({
        name: '',
        model: '',
        anio: '',
        version: '',
        kilo: '',
        estado: '',
        completeName: '',
        mail: '',
        phone: '',
    });

    useEffect(() => {
        const initialFieldsFilled = Object.values(formData)
            .slice(0, 6)
            .every((field) => field !== '');
        setShowNewInfo(initialFieldsFilled);
    }, [formData]);

    useEffect(() => {
        const allFieldsFilled = Object.values(formData).every((field) => field !== '');
        setButtonEnabled(allFieldsFilled);
    }, [formData]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <h1 className={styles.title}>
                    <span style={{ color: '#F7941D' }}> Cotiza </span>
                    tu auto ahora
                </h1>
            </div>
            <div className={styles.insideForm}>
                <form className={styles.formContainer} onSubmit={handleFormSubmit}>
                    <label className={styles.formLabel}>Nombre:</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={styles.formInput}
                    />
                    <label className={styles.formLabel}>Modelo:</label>
                    <input
                        type="text"
                        name="model"
                        value={formData.model}
                        onChange={handleChange}
                        className={styles.formInput}
                    />
                    <label className={styles.formLabel}>Año:</label>
                    <input
                        type="number"
                        inputMode="numeric"
                        name="anio"
                        min="0"
                        value={formData.anio}
                        onInput={handleNonNegativeInput}
                        onPaste={preventPasteNegative}
                        onChange={handleChange}
                        className={styles.formInput}
                    />
                    <label className={styles.formLabel}>Version:</label>
                    <input
                        type="text"
                        name="version"
                        value={formData.version}
                        onChange={handleChange}
                        className={styles.formInput}
                    />
                    <label className={styles.formLabel}>Kilometraje:</label>
                    <input
                        type="number"
                        inputMode="numeric"
                        name="kilo"
                        min="0"
                        onPaste={preventPasteNegative}
                        onInput={handleNonNegativeInput}
                        value={formData.kilo}
                        onChange={handleChange}
                        className={styles.formInput}
                    />
                    <label className={styles.formLabel}>Estado del interior:</label>
                    <input
                        type="text"
                        name="estado"
                        value={formData.estado}
                        onChange={handleChange}
                        className={styles.formInput}
                    />

                    {showNewInfo && (
                        <>
                            <div className={styles.titleContainerNuevo}>
                                <h1 className={styles.title}>
                                    <span style={{ color: '#F7941D' }}> Cotiza </span>
                                    tu auto ahora
                                </h1>
                            </div>
                            <label className={styles.formLabel}>Nombre completo:</label>
                            <input
                                type="text"
                                name="completeName"
                                value={formData.completeName}
                                onChange={handleChange}
                                className={styles.formInput}
                            />
                            <label className={styles.formLabel}>Correo electrónico:</label>
                            <input
                                type="email"
                                name="mail"
                                value={formData.mail}
                                onChange={handleChange}
                                className={styles.formInput}
                            />
                            <label className={styles.formLabel}>Teléfono:</label>
                            <input
                                type="text"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                className={`${styles.formInput} ${styles.phoneInput}`}
                            />
                        </>
                    )}
                    <div className={styles.buttonContainer}>
                        {isSubmitting ? (
                            <Spinner />
                        ) : (
                            <button
                                type="submit"
                                className={`${styles.formSubmit} ${
                                    !buttonEnabled ? styles.formSubmitDisabled : ''
                                }`}
                                disabled={!buttonEnabled}
                            >
                                <p>Siguiente</p>
                            </button>
                        )}
                    </div>
                </form>
            </div>

            <div className={styles.cartas}>
                <div className={styles.cardContainer}>
                    <div className={styles.imgContainer}>
                        <img src={motor} className={styles.img1} alt="Motor" />
                    </div>
                    <div className={styles.textContainer}>
                        <h2 className={styles.cardTitle}>Verificación profesional</h2>
                        <p>
                            Realizamos una verificación técnica del vehículo en nuestros predios. La
                            inspección cuenta con un peritaje de 280 puntos del vehículo. Con el
                            resultado completo de la verificación te haremos la oferta final para la
                            compra de tu vehículo.
                        </p>
                    </div>
                </div>
                <div className={styles.cardContainer}>
                    <div className={styles.imgContainer}>
                        <img src={billete} className={styles.img1} alt="Billete" />
                    </div>
                    <div className={styles.textContainer}>
                        <h2 className={styles.cardTitle}>Obtén una cotización Online</h2>
                        <p>
                            En nuestra página web, podrás obtener una cotización justa para tu auto
                            usado en cuestión de minutos. Simplemente ingresa la información básica
                            de tu vehículo, como la marca, modelo, año y kilometraje, y obtén una
                            oferta en tiempo real.
                        </p>
                    </div>
                </div>
                <div className={styles.cardContainer}>
                    <div className={styles.imgContainer}>
                        <img src={conferencia} className={styles.img1} alt="Conferencia" />
                    </div>
                    <div className={styles.textContainer}>
                        <h2 className={styles.cardTitle}>Gestión de transferencia</h2>
                        <p>
                            Para asegurar y garantizar todo el proceso de compra y venta de
                            automóviles, en Paternal Motor tenemos: una escribanía propia que te
                            garantizará toda la documentación de los vehículos y una sucursal
                            bancaria del Santander .
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
