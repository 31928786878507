import React from 'react';
import styles from './Cards.module.css';
import carro from '../../assets/carro.png';
import reserva from '../../assets/reserva-en-linea.png';
import llave from '../../assets/llave.png';

const Cards = () => {
    return (
        <div className={styles.container}>
            <div className={styles.cardContainer}>
                <div className={styles.imgContainer}>
                    <img src={carro} className={styles.img1} alt="Auto" />
                </div>
                <div className={styles.textContainer}>
                    <p className={styles.title}>
                        <span style={{ color: '#F78B07' }}>Busca </span>tu auto ideal
                    </p>
                    <p className={styles.subtitle}>Filtra por Año, Marca o Precio</p>
                </div>
            </div>

            <div className={styles.cardContainer}>
                <div className={styles.imgContainer}>
                    <img src={reserva} className={styles.img2} alt="Reserva" />
                </div>
                <div className={styles.textContainer}>
                    <p className={styles.title}>
                        <span style={{ color: '#F78B07' }}>Reserva </span>al instante
                    </p>
                    <p className={styles.subtitle}>Para asegurar que sea tuyo</p>
                </div>
            </div>

            <div className={styles.cardContainer}>
                <div className={styles.imgContainer}>
                    <img src={llave} className={styles.img3} alt="Llave" />
                </div>
                <div className={styles.textContainer}>
                    <p className={styles.title}>
                        <span style={{ color: '#F78B07' }}>Disfruta</span> esta experiencia
                    </p>
                    <p className={styles.subtitle}>En pocos días manejas tu auto</p>
                </div>
            </div>
        </div>
    );
};

export default Cards;
