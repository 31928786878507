import React from 'react';
import styles from '.././styles/ConsultedCar/ConsultedCar.module.css';
import MainSection from '../components/ConsultedCar/MainSection';
import Layout from '../components/layout/Layout';

export function ConsultedCar() {
    return (
        <Layout>
            <div className={styles.container}>
                <MainSection />
            </div>
        </Layout>
    );
}
