import React from 'react';
import styles from '.././styles/Sell/Sell.module.css';
import Layout from '../components/layout/Layout';
import { HeroSection } from '../components/Sell/HeroSection';
import { List } from '../components/Sell/List';

export function Sell() {
    return (
        <Layout>
            <div className={styles.container}>
                <HeroSection />
                <List />
            </div>
        </Layout>
    );
}
