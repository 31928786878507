import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../styles/HomePage/HeroSection.module.css';
import car from '../../assets/car.png';

export const HeroSection = () => {
    return (
        <div className={styles.container}>
            <div className={styles.insideDiv}>
                <div className={styles.textContainer}>
                    <h1 className={styles.title}>
                        El primer paso para tener tu auto favorito te está esperando{' '}
                        <span style={{ color: '#F7941D' }}>con nosotros</span>
                    </h1>
                    <h2 className={styles.subtitle}>¿Qué auto estás buscando?</h2>
                    <Link to="/used" style={{ textDecoration: 'none' }}>
                        <button className={styles.buttonContainer}>
                            <p>Ver Opciones</p>
                        </button>
                    </Link>
                </div>
                <img className={styles.carImage} src={car} alt="Car"></img>
            </div>
        </div>
    );
};
