import React from 'react';
import styles from '../../styles/HomePage/AboutUs.module.css';
import { BsDot } from 'react-icons/bs';

export const AboutUs = () => {
    return (
        <div className={styles.container}>
            <div className={styles.insideDiv}>
                <div className={styles.textContainer}>
                    <div className={styles.leftSide}>
                        <h3>Sobre nosotros</h3>
                        <h1>Una plataforma pensada para que encuentres lo que buscas</h1>
                    </div>
                    <div className={styles.rightSide}>
                        <h5>
                            <p>
                                <BsDot />
                            </p>
                            Nos dedicamos a ofrecer a nuestros clientes la mejor experiencia de
                            compra de autos en línea, con una interfaz fácil de usar y una
                            navegación sencilla.
                        </h5>
                        <h5>
                            <p>
                                <BsDot />
                            </p>
                            En nuestro sitio web, puedes encontrar descripciones detalladas de cada
                            automóvil, incluyendo información sobre el modelo, año, kilometraje,
                            características y precios.
                        </h5>
                        <h5>
                            <p>
                                <BsDot />
                            </p>
                            Además, nuestro equipo de expertos en ventas está siempre disponible
                            para responder cualquier pregunta que puedas tener y ayudarte a
                            encontrar tu próximo proyecto. ¡Explora nuestro inventario en línea hoy
                            y encuentra tu próximo vehículo con nosotros!
                        </h5>
                        <h5>
                            <p>
                                <BsDot />
                            </p>
                            Ofrecemos un servicio integral de repuestos para las unidades. Como
                            desarmadero certificado y en plena conformidad con la Ley 25.761,
                            tenemos la capacidad de proporcionar piezas de alta calidad a partir de
                            una amplia variedad de vehículos. No dude en consultarnos sobre la
                            disponibilidad de repuestos específicos para su unidad
                        </h5>
                        <h5>
                            <p>
                                <BsDot />
                            </p>
                            las unidades, solo se entregan una vez realizada la transferencia
                            correspondiente de las mismas
                        </h5>
                        <h3 classname={styles.mobileRight}>
                            Nos dedicamos a ofrecer a nuestros clientes la mejor experiencia de
                            compra de autos en línea, con una interfaz fácil de usar y una
                            navegación sencilla.
                        </h3>
                        <h3>
                            En nuestro sitio web, puedes encontrar descripciones detalladas de cada
                            automóvil, incluyendo información sobre el modelo, año, kilometraje,
                            características, precios.
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    );
};
