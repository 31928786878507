import React from 'react';
import { Navbar } from '../Navbar';
import WhatsAppButton from '../whatsAppButton/WhatsAppButton';
import Footer from '../footer/Footer';
const Layout = ({ children }) => {
    return (
        <div>
            <Navbar />
            {children}
            <WhatsAppButton />
            <Footer />
        </div>
    );
};

export default Layout;
