import { React, useState, useEffect } from 'react';
import styles from '../../styles/HomePage/Ingresos.module.css';
import car from '../../assets/orangeCar.png';
import ShoppingCard from '../shoppingCard/ShoppingCard';
import Gallery from '../gallery/Gallery';
import { collection, onSnapshot, query, where, orderBy, limit } from '@firebase/firestore';
import { db } from '../../firebase';
import { get } from 'lodash';
import { Link } from 'react-router-dom';

export const Ingresos = () => {
    const [width, setWindowWidth] = useState(0);
    const [data, setData] = useState([]);

    useEffect(() => {
        const carsRef = collection(db, 'cars');
        const q = query(
            carsRef,
            where('sold', '==', 'no'),
            orderBy('serverTimestamp', 'desc'),
            limit(5)
        );
        const unsub = onSnapshot(
            q,
            async (snapShot) => {
                const list = [];
                for (const doc of snapShot.docs) {
                    const carData = doc.data();
                    list.push({
                        id: doc.id,
                        ...carData,
                    });
                }
                setData(list);
            },
            (error) => {
                console.log(error);
            }
        );

        const updateDimensions = () => {
            const width = window.innerWidth;
            setWindowWidth(width);
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => {
            unsub();
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.insideDiv}>
                <div className={styles.titleArea}>
                    <h1>Nuevos Ingresos</h1>
                    <h3>Encuentra tu nuevo auto</h3>
                </div>
                <Link to="/used" style={{ textDecoration: 'none' }}>
                    <button className={styles.buttonVerTodos}>
                        <img className={styles.carImage} src={car} alt="Car"></img>
                        <p>Ver todos</p>
                    </button>
                </Link>
            </div>
            <div className={styles.cartas}>
                {width > 1440 ? (
                    data
                        .slice(0, 4)
                        .map((car) => (
                            <ShoppingCard
                                key={car.id}
                                id={car.id}
                                size="Size"
                                price={car.soldFor ? car.soldFor : 'N/A'}
                                brand={car.brand ? car.brand : 'N/A'}
                                year={car.year ? car.year : 'N/A'}
                                mileage={car.mileage ? car.mileage : 'N/A'}
                                imageUrl={get(car, 'images[0]', 'fallbackImageUrl')}
                            />
                        ))
                ) : width > 1280 ? (
                    data
                        .slice(0, 3)
                        .map((car) => (
                            <ShoppingCard
                                key={car.id}
                                id={car.id}
                                size="Size"
                                price={car.soldFor ? car.soldFor : 'N/A'}
                                brand={car.brand ? car.brand : 'N/A'}
                                year={car.year ? car.year : 'N/A'}
                                mileage={car.mileage ? car.mileage : 'N/A'}
                                imageUrl={get(car, 'images[0]', 'fallbackImageUrl')}
                            />
                        ))
                ) : width > 768 ? (
                    data
                        .slice(0, 2)
                        .map((car) => (
                            <ShoppingCard
                                key={car.id}
                                id={car.id}
                                size="Size"
                                price={car.soldFor ? car.soldFor : 'N/A'}
                                brand={car.brand ? car.brand : 'N/A'}
                                year={car.year ? car.year : 'N/A'}
                                mileage={car.mileage ? car.mileage : 'N/A'}
                                imageUrl={get(car, 'images[0]', 'fallbackImageUrl')}
                            />
                        ))
                ) : (
                    <div className={styles.galleryContainer}>
                        <Gallery ordenIngreso={true} />
                    </div>
                )}
            </div>
        </div>
    );
};
