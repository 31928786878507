import React from 'react';
import styles from './WhatsApp.module.css';
import wppLogo from '../../assets/wpp.png';
const WhatsAppButton = ({
    defaultMessage = 'Hola, estoy interesado en conocer más sobre los vehículos disponibles en Paternal Motor. ¿Podrías ayudarme?',
}) => {
    const encodedMessage = encodeURIComponent(defaultMessage);
    return (
        <a
            href={`https://api.whatsapp.com/send?phone=5491128954906&text=${encodedMessage}`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.container}
        >
            <img className={styles.img} src={wppLogo} alt="LogoPrincipal"></img>
        </a>
    );
};

export default WhatsAppButton;
