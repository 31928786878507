import './new.scss';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import { useEffect, useState } from 'react';
import { addDoc, collection, doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { storage, db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const New = ({ title }) => {
    const [files, setFiles] = useState([]);
    const [data, setData] = useState({});
    const navigate = useNavigate();
    const [per, setPerc] = useState(null);
    const [transferido, setTransferido] = useState('');
    const [showTallerInput, setShowTallerInput] = useState(false);
    const [showCompaniaInput, setShowCompaniaInput] = useState(false);
    const [ubicacion, setUbicacion] = useState('');
    const [showOtroCompaniaInput, setShowOtroCompaniaInput] = useState(false);
    const [ubicaciondoc, setUbicaciondoc] = useState('');
    const [showFolderInput, setShowFolderInput] = useState(false);
    const [showOtroUbiDoc, setshowOtroUbiDoc] = useState(false);
    const [carLocation3, setCarLocation3] = useState('');
    const [showOtroUbiInput, setShowOtroUbiInput] = useState(false);
    const [vendido, setVendido] = useState(null);
    const [showAcuantoSeVendio, setShowAcuantoSeVendio] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleVendido = (value) => {
        setVendido(value);
        setShowAcuantoSeVendio(true);
    };

    const handleTransferido = (value) => {
        setTransferido(value);
        setData({ ...data, transferido: value });
    };
    const validateForm = () => {
        const requiredFields = [
            'patente',
            'marca',
            'version',
            'modelo',
            'anio',
            'kilometraje',
            'observacionesgenerales',
            'transferido',
            'grua',
            'fecharecepcion',
            'ubicacion',
            'documentacion',
            'documentacionfaltante',
            'ubicaciondoc',
            'acuanto',
            'acuantosevendio',
            'recomendado',
        ];

        if (vendido !== 'no' && vendido !== 'si' && vendido !== 'No' && vendido !== 'Si') {
            alert('Por favor, completa el campo vendido');
            return false;
        }

        for (const field of requiredFields) {
            if (!data[field]) {
                alert(`Por favor, completa el campo ${field}.`);
                return false;
            }
        }

        if (files.length === 0) {
            alert('Por favor, carga al menos una imagen.');
            return false;
        }

        return true;
    };

    const handleUbicacion = (value) => {
        setUbicacion(value);
        setData((prevData) => ({ ...prevData, ubicacion: value }));
        setShowTallerInput(value === 'Taller');
        setShowCompaniaInput(value === 'Compania');
        setShowOtroUbiInput(value === 'Otro');
    };

    const handleUbicacionDos = (value) => {
        setData((prevData) => ({ ...prevData, ubicaciondos: value }));
        setShowOtroCompaniaInput(value === 'Otro');
        setCarLocation3(value);
    };

    const handleUbicacionDoc = (value) => {
        setUbicaciondoc(value);
        setData((prevData) => ({ ...prevData, ubicaciondoc: value }));
        setShowFolderInput(value === 'Carpeta Nro...');
        setshowOtroUbiDoc(value === 'Otro');
    };

    const userInputs = [
        {
            id: 'patente',
            label: 'Patente',
            type: 'text',
        },
        {
            id: 'marca',
            label: 'Marca',
            type: 'text',
        },
        {
            id: 'version',
            label: 'Version',
            type: 'text',
        },
        {
            id: 'modelo',
            label: 'Modelo',
            type: 'text',
        },
        {
            id: 'anio',
            label: 'Año',
            type: 'number',
            inputMode: 'numeric',
        },
        {
            id: 'kilometraje',
            label: 'Kilometraje',
            type: 'number',
            inputMode: 'numeric',
        },
        {
            id: 'observacionesgenerales',
            label: 'Observaciones generales',
            type: 'text',
        },
        {
            id: 'transferido',
            label: 'Transferido',
            type: 'buttons',
        },
        {
            id: 'grua',
            label: 'Grua que lo trajo',
            type: 'text',
        },
        {
            id: 'fecharecepcion',
            label: 'Fecha de recepción',
            type: 'date',
        },
        {
            id: 'ubicacion',
            label: 'Ubicación del auto',
            type: 'dropdown',
            options: ['Otro', 'Octubre', 'Compania', 'Taller'],
        },
        showTallerInput && {
            id: 'ubicaciondos',
            label: '¿Qué taller es?',
            type: 'text',
        },
        showTallerInput && {
            id: 'tallerid1',
            label: 'Ubicación taller',
            type: 'text',
        },
        showTallerInput && {
            id: 'tallerid2',
            label: 'Fecha en que se envió a taller',
            type: 'date',
        },
        showTallerInput && {
            id: 'tallerid3',
            label: 'Costo de arreglo',
            type: 'text',
        },
        showTallerInput && {
            id: 'tallerid4',
            label: 'Pagado',
            type: 'text',
        },
        showTallerInput && {
            id: 'tallerid5',
            label: 'Por pagar',
            type: 'text',
        },
        showTallerInput && {
            id: 'tallerid6',
            label: 'Costo en repuestos',
            type: 'text',
        },
        showTallerInput && {
            id: 'tallerid7',
            label: 'Costo en mano de obra',
            type: 'text',
        },
        showCompaniaInput
            ? {
                  id: 'ubicaciondos',
                  label: 'Que Compañia es?',
                  type: 'dropdown',
                  options: ['Rio Uruguay', 'Caledonia', 'Rivadavia', 'La Segunda', 'Otro'],
              }
            : null,
        showOtroUbiInput
            ? {
                  id: 'ubicaciondos',
                  label: 'Que otro es?',
                  type: 'text',
              }
            : null,
        showOtroCompaniaInput
            ? {
                  id: 'ubicaciontres',
                  label: 'Ingrese el nombre de la Compañía',
                  type: 'text',
              }
            : null,
        {
            id: 'documentacion',
            label: 'Documentación que hay',
            type: 'text',
        },
        {
            id: 'documentacionfaltante',
            label: 'Documentación que falta',
            type: 'text',
        },
        {
            id: 'ubicaciondoc',
            label: 'Ubicación de la documentación',
            type: 'dropdown',
            options: ['Gestora', 'Carpeta Nro...', 'Triunvirato', 'Octubre', 'Otro'],
        },
        showFolderInput
            ? {
                  id: 'ubicaciondocdos',
                  label: 'Carpeta Nro.',
                  type: 'text',
              }
            : null,
        showOtroUbiDoc
            ? {
                  id: 'ubicaciondocdos',
                  label: 'Observaciones',
                  type: 'text',
              }
            : null,
        {
            id: 'acuanto',
            label: 'A cuanto se compró (USD)',
            type: 'number',
            inputMode: 'numeric',
        },
        {
            id: 'vendido',
            label: 'Vendido',
            type: 'buttons',
        },

        {
            id: 'acuantosevendio',
            label: 'A cuanto se va a vender/se vendió',
            type: 'number',
            inputMode: 'numeric',
        },
        {
            id: 'recomendado',
            label: 'Numero de escala de recomendación',
            type: 'number',
            inputMode: 'numeric',
        },
    ];

    useEffect(() => {
        const uploadFiles = () => {
            const promises = files.map((file) => {
                const name = new Date().getTime() + file.name;
                const storageRef = ref(storage, name);
                const uploadTask = uploadBytesResumable(storageRef, file);

                return new Promise((resolve, reject) => {
                    uploadTask.on(
                        'state_changed',
                        (snapshot) => {
                            const progress =
                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                            setPerc(progress);
                            switch (snapshot.state) {
                                case 'paused':
                                    console.log('Upload is paused');
                                    break;
                                case 'running':
                                    console.log('Upload is running');
                                    break;
                                default:
                                    break;
                            }
                        },
                        (error) => {
                            reject(error);
                        },
                        () => {
                            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                console.log('File available at', downloadURL);
                                resolve(downloadURL);
                            });
                        }
                    );
                });
            });

            Promise.all(promises).then((urls) => {
                setData((prev) => ({ ...prev, images: urls }));
            });
        };
        files.length > 0 && uploadFiles();
    }, [files]);

    const handleInput = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        setData((prevData) => ({ ...prevData, [id]: value }));
    };

    const handleAdd = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const formData = {
            ...data,
            vendido,
            transferido,
        };

        if (!validateForm(formData)) {
            setIsSubmitting(false);
            return;
        }

        try {
            const newDoc = {
                licencePlate: data.patente,
                brand: data.marca,
                version: data.version,
                model: data.modelo,
                year: data.anio,
                mileage: data.kilometraje,
                gObservations: data.observacionesgenerales,
                transferred: data.transferido,
                tow: data.grua,
                repectionDate: data.fecharecepcion,
                carLocation: data.ubicacion,
                carLocation2: data.ubicaciondos || 'No aplica',
                carLocation3: data.ubicaciontres || 'No aplica',
                docs: data.documentacion,
                missingDocs: data.documentacionfaltante,
                docsLocation: data.ubicaciondoc,
                docsLocation2: data.ubicaciondocdos || 'No aplica',
                ...(data.ubicaciondoc === 'Carpeta Nro...' &&
                    data.ubicaciondocdos !== '' && {
                        docsLocation2: data.ubicaciondocdos,
                    }),
                purchasedFor: data.acuanto,
                sold: vendido,
                soldFor: data.acuantosevendio,
                images: data.images,
                mechLocation: data.tallerid1 || 'No aplica',
                mechDate: data.tallerid2 || 'No aplica',
                mechCost: data.tallerid3 || 'No aplica',
                mechPaid: data.tallerid4 || 'No aplica',
                mechToPay: data.tallerid5 || 'No aplica',
                mechCostReplacement: data.tallerid6 || 'No aplica',
                mechHandPowerCost: data.tallerid7 || 'No aplica',
                serverTimestamp: serverTimestamp(),
                recommended:
                    data.recomendado && !isNaN(parseInt(data.recomendado))
                        ? parseInt(data.recomendado)
                        : null,
            };

            const res = await addDoc(collection(db, 'cars'), newDoc);
            navigate(-1);
        } catch (err) {
            console.log(err);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Navbar />
                <div className="top">
                    <h1>{title}</h1>
                </div>
                <div className="bottom">
                    <div className="left">
                        {files.length === 0 ? (
                            <img
                                src={
                                    'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg'
                                }
                                alt=""
                            />
                        ) : (
                            files.map((file) => (
                                <img key={file.name} src={URL.createObjectURL(file)} alt="" />
                            ))
                        )}
                    </div>
                    <div className="right">
                        <form onSubmit={handleAdd}>
                            <div className="formInput">
                                <label htmlFor="file">
                                    Image: <DriveFolderUploadOutlinedIcon className="icon" />
                                </label>
                                <input
                                    type="file"
                                    id="file"
                                    onChange={(e) => setFiles([...files, ...e.target.files])}
                                    style={{ display: 'none' }}
                                    multiple
                                />
                            </div>
                            {userInputs.filter(Boolean).map((input) =>
                                input.type === 'buttons' && input.id === 'vendido' ? (
                                    <div className="formInput" key={input.id}>
                                        <label>{input.label}</label>
                                        <div>
                                            <button
                                                type="button"
                                                disabled={vendido === 'si'}
                                                onClick={() => handleVendido('si')}
                                            >
                                                Sí
                                            </button>
                                            <button
                                                type="button"
                                                disabled={vendido === 'no'}
                                                onClick={() => handleVendido('no')}
                                            >
                                                No
                                            </button>
                                        </div>
                                    </div>
                                ) : input.type === 'buttons' ? (
                                    <div className="formInput" key={input.id}>
                                        <label>{input.label}</label>
                                        <div>
                                            <button
                                                type="button"
                                                disabled={transferido === 'si'}
                                                onClick={() => handleTransferido('si')}
                                            >
                                                Sí
                                            </button>
                                            <button
                                                type="button"
                                                disabled={transferido === 'no'}
                                                onClick={() => handleTransferido('no')}
                                            >
                                                No
                                            </button>
                                        </div>
                                    </div>
                                ) : input.id === 'acuantosevendio' ? (
                                    showAcuantoSeVendio && (
                                        <div className="formInput" key={input.id}>
                                            <label>
                                                {vendido === 'si'
                                                    ? 'A cuanto se vendió'
                                                    : vendido === 'no'
                                                    ? 'A cuanto se va a vender'
                                                    : ''}
                                            </label>
                                            <input
                                                id="acuantosevendio"
                                                type="number"
                                                inputMode="numeric"
                                                onChange={handleInput}
                                            />
                                        </div>
                                    )
                                ) : input.type === 'dropdown' && input.id === 'ubicacion' ? (
                                    <div className="formInput" key={input.id}>
                                        <label>{input.label}</label>
                                        <select
                                            id={input.id}
                                            value={ubicacion}
                                            onChange={(e) => handleUbicacion(e.target.value)}
                                        >
                                            <option value="">Selecciona una opción</option>
                                            {input.options.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                ) : input.type === 'dropdown' && input.id === 'ubicaciondos' ? (
                                    <div className="formInput" key={input.id}>
                                        <label>{input.label}</label>
                                        <select
                                            id={input.id}
                                            value={data.ubicaciondos || ''}
                                            onChange={(e) => handleUbicacionDos(e.target.value)}
                                        >
                                            <option value="">Selecciona una opción</option>
                                            {input.options.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                ) : input.type === 'dropdown' && input.id === 'ubicaciondoc' ? (
                                    <div className="formInput" key={input.id}>
                                        <label>{input.label}</label>
                                        <select
                                            id={input.id}
                                            value={ubicaciondoc}
                                            onChange={(e) => handleUbicacionDoc(e.target.value)}
                                        >
                                            <option value="">Selecciona una opción</option>
                                            {input.options.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                ) : showFolderInput && input.id === 'ubicaciondocdos' ? (
                                    <div className="formInput" key={input.id}>
                                        <label>{input.label}</label>
                                        <input
                                            id={input.id}
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            onChange={handleInput}
                                        />
                                    </div>
                                ) : (
                                    <div className="formInput" key={input.id}>
                                        <label>{input.label}</label>
                                        <input
                                            id={input.id}
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            onChange={handleInput}
                                        />
                                    </div>
                                )
                            )}
                            <button
                                disabled={isSubmitting || (per !== null && per < 100)}
                                type="submit"
                            >
                                Enviar
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default New;
