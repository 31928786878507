import React from 'react';
import styles from './Footer.module.css';
import logoBuoza from '../../assets/logo-buoza.png';
import logoPat from '../../assets/footer-logo.png';
import insta from '../../assets/instagram.png';
import fb from '../../assets/facebook.png';
import telegram from '../../assets/telegram.png';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.contentContainer}>
                    <h1 className={styles.logoPat}>
                        {' '}
                        Agencia <span style={{ color: '#F7941D' }}> Paternal</span> Motor
                    </h1>
                    <div className={styles.secondColumn}>
                        <h5 className={styles.title}>Navegación</h5>
                        <a className={styles.navItem} href="/" style={{ textDecoration: 'none' }}>
                            Inicio
                        </a>
                        <a
                            className={styles.navItem}
                            href="/used"
                            style={{ textDecoration: 'none' }}
                        >
                            Comprar
                        </a>
                        <a
                            className={styles.navItem}
                            href="/sell"
                            style={{ textDecoration: 'none' }}
                        >
                            Vender
                        </a>
                    </div>
                    <div className={styles.secondColumn}>
                        <h5 className={styles.titleContact}>Contacto</h5>
                        <div className={styles.navContainer}>
                            <p className={styles.navItem}>+54 1134171008</p>
                            <p className={styles.navItem}>
                                Av. Constituyente 2602, esq. 12 de Octubre
                            </p>
                            <p className={styles.navItem}>C.P: 1617</p>
                        </div>
                    </div>
                    <div className={styles.thirdColumn}>
                        <h5 className={styles.title}>Nuestras redes</h5>
                        <div className={styles.imgsContainer}>
                            <img src={insta} alt="Telegram" className={styles.socialMedia} />
                            <img src={fb} alt="Instagram" className={styles.socialMedia} />
                            <img src={telegram} alt="Facebook" className={styles.socialMedia} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.bottomContainer}>
                <img src={logoBuoza} alt="Logo buoza" className={styles.logoB} />
                <span className={styles.textB}>
                    DISEÑO Y DESARROLLO POR{' '}
                    <a
                        href="https://buoza.com/"
                        target="_blank"
                        rel="noreferrer"
                        className={styles.linkB}
                    >
                        BUOZA.COM
                    </a>
                </span>
            </div>
        </>
    );
};

export default Footer;
