import React from 'react';
import styles from '.././styles/Used/Used.module.css';
import Cards from '../components/cards/Cards';
import { HeroSection } from '../components/Used/HeroSection';
import { Store } from '../components/Used/Store';
import Layout from '../components/layout/Layout';

export function Used() {
    return (
        <Layout>
            <div className={styles.container}>
                <HeroSection />
                <Cards />
                <Store />
            </div>
        </Layout>
    );
}
