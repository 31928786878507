import React from 'react';
import styles from './HeroSection.module.css';
import car from '../../assets/tiguan.png';

export const HeroSection = () => {
    return (
        <div className={styles.container}>
            <div className={styles.insideDiv}>
                <div className={styles.textContainer}>
                    <h1 className={styles.title}>
                        Elije la marca{' '}
                        <span style={{ color: '#F7941D' }}> que estas buscando </span>y nosotros la
                        encontramos
                    </h1>
                </div>

                <img className={styles.img} src={car} alt="Car"></img>
            </div>
        </div>
    );
};
