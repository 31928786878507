export const userInputs = [
  {
    id: 'patente',
    label: 'Patente',
    type: 'text',
  },
  {
    id: 'marca',
    label: 'Marca',
    type: 'text',
  },
  {
    id: 'version',
    label: 'Version',
    type: 'text',
  },
  {
    id: 'modelo',
    label: 'Modelo',
    type: 'text',
  },
  {
    id: 'anio',
    label: 'Año',
    type: 'text',
  },
  {
    id: 'kilometraje',
    label: 'Kilometraje',
    type: 'text',
  },
  {
    id: 'observacionesgenerales',
    label: 'Observaciones generales',
    type: 'text',
  },
  {
    id: 'transferido',
    label: 'Transferido',
    type: 'text',
  },
  {
    id: 'grua',
    label: 'Grua que lo trajo',
    type: 'text',
  },
  {
    id: 'fecharecepcion',
    label: 'Fecha de recepción',
    type: 'date',
  },
  {
    id: 'ubicacion',
    label: 'Ubicación del auto',
    type: 'text',
  },
  {
    id: 'ubicaciondos',
    label: 'Ubicación del auto 2',
    type: 'text',
  },
  {
    id: 'documentacion',
    label: 'Documentación que hay',
    type: 'text',
  },
  {
    id: 'documentacionfaltante',
    label: 'Documentación que falta',
    type: 'text',
  },
  {
    id: 'ubicaciondoc',
    label: 'Ubicación de la documentación',
    type: 'text',
  },
  {
    id: 'ubicaciondocdos',
    label: 'Ubicación de la documentación 2',
    type: 'text',
  },
  {
    id: 'acuanto',
    label: 'A cuanto se compró (USD)',
    type: 'text',
  },
  {
    id: 'vendido',
    label: 'Vendido',
    type: 'text',
  },
  {
    id: 'acuantosevendio',
    label: 'A cuanto se va a vender/se vendió',
    type: 'text',
  },
];
