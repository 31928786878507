import React from 'react';
import './sidebar.scss';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import LogoutIcon from '@mui/icons-material/Logout';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { Link } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        const auth = getAuth();
        try {
            await signOut(auth);
            navigate('/admin/login');
        } catch (error) {
            console.error('Error al cerrar sesión:', error);
        }
    };

    return (
        <div className="sidebar">
            <div className="top">
                <Link to="/admin/" style={{ textDecoration: 'none' }}>
                    <span className="logo">Paternal Motor</span>
                </Link>
            </div>
            <hr />
            <div className="center">
                <ul>
                    <p className="title">HOME</p>
                    <Link to="/admin/" style={{ textDecoration: 'none' }}>
                        <li>
                            <DashboardIcon className="icon" />
                            <span>Dashboard</span>
                        </li>
                    </Link>
                    <p className="title">LISTAS</p>
                    <Link to="/admin/users" style={{ textDecoration: 'none' }}>
                        <li>
                            <DirectionsCarFilledIcon className="icon" />
                            <span>Autos</span>
                        </li>
                    </Link>
                    <p className="title">OTROS</p>
                    <li onClick={handleLogout}>
                        <LogoutIcon className="icon" />
                        <span>Logout</span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
