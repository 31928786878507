import './single.scss';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import Carousel, { consts } from 'react-elastic-carousel';
import { IoIosArrowBack } from 'react-icons/io';
import { IoIosArrowForward } from 'react-icons/io';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const Single = () => {
    const { userId } = useParams();
    const [data, setData] = useState({ images: [] });
    const [updatedData, setUpdatedData] = useState({});
    const [inputData, setInputData] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const navigate = useNavigate();
    const [isSold, setIsSold] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [selectedImage, setSelectedImage] = useState('');

    const handleImageClick = (imgUrl) => {
        setSelectedImage(imgUrl);
    };

    const handleImageDrag = (index) => {
        if (isEditing) {
            setSelectedImage(data.images[index]);
        }
    };

    const handleImageDrop = (dragIndex, dropIndex) => {
        if (isEditing) {
            const draggedImage = data.images[dragIndex];
            const updatedImages = [...data.images];
            updatedImages.splice(dragIndex, 1);
            updatedImages.splice(dropIndex, 0, draggedImage);
            setData((prevData) => ({
                ...prevData,
                images: updatedImages,
            }));
        }
    };

    const toggleEditMode = () => {
        setIsEditing(!isEditing);
    };

    const [width, setWindowWidth] = useState(0);

    const updateDimensions = () => {
        const width = window.innerWidth;
        setWindowWidth(width);
    };

    function myArrow({ type, onClick, isEdge }) {
        const pointer =
            type === consts.PREV ? (
                <div className="arrowContainer">
                    <div className="arrows">{<IoIosArrowBack />}</div>
                </div>
            ) : (
                <div className="arrowContainer">
                    <div className="arrows">{<IoIosArrowForward />}</div>
                </div>
            );
        return (
            <div onClick={onClick} disabled={isEdge}>
                {pointer}
            </div>
        );
    }

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        const fetchData = async () => {
            try {
                const docRef = doc(db, 'cars', userId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    console.log('Document data:', docSnap.data());
                } else {
                    console.log('No such document!');
                }
                setData(docSnap.data());
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();

        console.log('segundo log', data);
        data.sold === 'si' ? setIsSold(true) : setIsSold(false);

        setIsLoading(false);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, [userId]);

    const handleInputChange = (key, value) => {
        setInputData({
            ...updatedData,
            [key]: value,
        });
    };

    const updateCarData = async () => {
        try {
            const docRef = doc(db, 'cars', userId);
            await updateDoc(docRef, {
                ...inputData,
                images: data.images, // Se utiliza el nuevo orden de imágenes
            });
            alert('Datos actualizados correctamente');
            navigate(-1);
        } catch (error) {
            console.error('Error al actualizar los datos:', error);
        }
    };

    const Image = ({ index, image }) => {
        const [{ isDragging }, drag] = useDrag(
            () => ({
                type: 'image',
                item: { index, image },
                canDrag: isEditing, // Solo permite el arrastre cuando isEditing es true
                collect: (monitor) => ({
                    isDragging: monitor.isDragging(),
                }),
            }),
            [isEditing]
        );

        const [, drop] = useDrop(() => ({
            accept: 'image',
            hover(item) {
                if (item.index === index) {
                    return;
                }
                const dragIndex = item.index;
                const hoverIndex = index;
                handleImageDrop(dragIndex, hoverIndex);
                item.index = hoverIndex;
            },
        }));

        const opacity = isDragging ? 0.4 : 1;

        return (
            <img
                ref={(node) => drag(drop(node))}
                key={image}
                src={image}
                alt=""
                className="itemImga"
                style={{ opacity }}
                onClick={() => handleImageClick(image)}
            />
        );
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="single">
                <Sidebar />
                <div className="singleContainer">
                    <Navbar />
                    <div className="top">
                        {isEditing ? (
                            <div className="editButton" onClick={toggleEditMode}>
                                Ver
                            </div>
                        ) : (
                            <div className="editButton" onClick={toggleEditMode}>
                                Editar
                            </div>
                        )}
                        <div>
                            <h1>Información completa</h1>

                            <div className="left">
                                <div className="item">
                                    <div className="itemImgs">
                                        <img
                                            src={selectedImage || data.images[0]}
                                            alt=""
                                            className="itemImgLarge"
                                        />
                                        <div className="itemImgWrapper">
                                            <Carousel
                                                enableSwipe={true}
                                                enableMouseSwipe={true}
                                                itemsToShow={4}
                                                itemsToScroll={1}
                                                pagination={false}
                                                renderArrow={myArrow}
                                            >
                                                {data.images.map((image, index) => (
                                                    <Image
                                                        key={image}
                                                        index={index}
                                                        image={image}
                                                    />
                                                ))}
                                            </Carousel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="right">
                            <div className="rightInside">
                                <div className="details">
                                    <h1 className="itemTitle">{data.licencePlate}</h1>
                                    <div className="detailItem">
                                        <p className="itemKey">Patente:</p>
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                className="itemValueInput"
                                                value={inputData.licencePlate ?? data.licencePlate}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        'licencePlate',
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        ) : (
                                            <p className="itemValue">
                                                {inputData.licencePlate ?? data.licencePlate}
                                            </p>
                                        )}
                                    </div>
                                    <div className="detailItem">
                                        <p className="itemKey">Marca:</p>
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                className="itemValueInput"
                                                value={inputData.brand ?? data.brand}
                                                onChange={(e) =>
                                                    handleInputChange('brand', e.target.value)
                                                }
                                            />
                                        ) : (
                                            <p className="itemValue">
                                                {inputData.brand ?? data.brand}
                                            </p>
                                        )}
                                    </div>
                                    <div className="detailItem">
                                        <p className="itemKey">Versión:</p>
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                className="itemValueInput"
                                                value={inputData.version ?? data.version}
                                                onChange={(e) =>
                                                    handleInputChange('version', e.target.value)
                                                }
                                            />
                                        ) : (
                                            <p className="itemValue">
                                                {inputData.version ?? data.version}
                                            </p>
                                        )}
                                    </div>
                                    <div className="detailItem">
                                        <p className="itemKey">Modelo:</p>
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                className="itemValueInput"
                                                value={inputData.model ?? data.model}
                                                onChange={(e) =>
                                                    handleInputChange('model', e.target.value)
                                                }
                                            />
                                        ) : (
                                            <p className="itemValue">
                                                {inputData.model ?? data.model}
                                            </p>
                                        )}
                                    </div>
                                    <div className="detailItem">
                                        <p className="itemKey">Año:</p>
                                        {isEditing ? (
                                            <input
                                                type="number"
                                                className="itemValueInput"
                                                value={inputData.year ?? data.year}
                                                onChange={(e) =>
                                                    handleInputChange('year', e.target.value)
                                                }
                                            />
                                        ) : (
                                            <p className="itemValue">
                                                {inputData.year ?? data.year}
                                            </p>
                                        )}
                                    </div>
                                    <div className="detailItem">
                                        <p className="itemKey">Kilometraje:</p>
                                        {isEditing ? (
                                            <input
                                                type="number"
                                                className="itemValueInput"
                                                value={inputData.mileage ?? data.mileage}
                                                onChange={(e) =>
                                                    handleInputChange('mileage', e.target.value)
                                                }
                                            />
                                        ) : (
                                            <p className="itemValue">
                                                {inputData.mileage ?? data.mileage}
                                            </p>
                                        )}
                                    </div>
                                    <div className="detailItem">
                                        <p className="itemKey">Recomendado:</p>
                                        {isEditing ? (
                                            <input
                                                type="number"
                                                className="itemValueInput"
                                                value={inputData.recommended ?? data.recommended}
                                                onChange={(e) =>
                                                    handleInputChange('recommended', e.target.value)
                                                }
                                            />
                                        ) : (
                                            <p className="itemValue">
                                                {inputData.recommended ?? data.recommended}
                                            </p>
                                        )}
                                    </div>
                                    <div className="detailItem">
                                        <p className="itemKey">Observaciones generales:</p>
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                className="itemValueInput"
                                                value={
                                                    inputData.gObservations ?? data.gObservations
                                                }
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        'gObservations',
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        ) : (
                                            <p className="itemValue">
                                                {inputData.gObservations ?? data.gObservations}
                                            </p>
                                        )}
                                    </div>
                                    <div className="detailItem">
                                        <p className="itemKey">Transferido:</p>
                                        {isEditing ? (
                                            <select
                                                className="itemValueInput"
                                                value={inputData.transferred ?? data.transferred}
                                                onChange={(e) =>
                                                    handleInputChange('transferred', e.target.value)
                                                }
                                            >
                                                <option value="si">si</option>
                                                <option value="no">no</option>
                                            </select>
                                        ) : (
                                            <p className="itemValue">
                                                {inputData.transferred ?? data.transferred}
                                            </p>
                                        )}
                                    </div>
                                    <div className="detailItem">
                                        <p className="itemKey">Grua que lo trajo:</p>
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                className="itemValueInput"
                                                value={inputData.tow ?? data.tow}
                                                onChange={(e) =>
                                                    handleInputChange('tow', e.target.value)
                                                }
                                            />
                                        ) : (
                                            <p className="itemValue">{inputData.tow ?? data.tow}</p>
                                        )}
                                    </div>
                                    <div className="detailItem">
                                        <p className="itemKey">Fecha de recepción:</p>
                                        {isEditing ? (
                                            <input
                                                type="date"
                                                className="itemValueInput"
                                                value={
                                                    inputData.repectionDate ?? data.repectionDate
                                                }
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        'repectionDate',
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        ) : (
                                            <p className="itemValue">
                                                {inputData.repectionDate ?? data.repectionDate}
                                            </p>
                                        )}
                                    </div>
                                    {data.carLocation !== 'Otro' ? (
                                        <div className="detailItem">
                                            <p className="itemKey">Ubicacion del auto:</p>
                                            {isEditing ? (
                                                <select
                                                    className="itemValueInput"
                                                    value={
                                                        inputData.carLocation ?? data.carLocation
                                                    }
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            'carLocation',
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="Taller">Taller</option>
                                                    <option value="Octubre">Octubre</option>
                                                    <option value="Compania">Compania</option>
                                                    <option value="Otro">Otro</option>
                                                </select>
                                            ) : (
                                                <p className="itemValue">
                                                    {inputData.carLocation ?? data.carLocation}
                                                </p>
                                            )}
                                        </div>
                                    ) : null}
                                    {data.carLocation === 'Otro' ? (
                                        <div className="detailItem">
                                            <p className="itemKey">Ubiacion del auto:</p>
                                            {isEditing ? (
                                                <input
                                                    type="text"
                                                    className="itemValueInput"
                                                    value={
                                                        inputData.carLocation2 ?? data.carLocation2
                                                    }
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            'carLocation2',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <p className="itemValue">
                                                    {inputData.carLocation2 ?? data.carLocation2}
                                                </p>
                                            )}
                                        </div>
                                    ) : data.carLocation !== 'Compania' &&
                                      data.carLocation2 !== 'No aplica' ? (
                                        <div className="detailItem">
                                            <p className="itemKey">Taller:</p>
                                            {isEditing ? (
                                                <input
                                                    type="text"
                                                    className="itemValueInput"
                                                    value={
                                                        inputData.carLocation2 ?? data.carLocation2
                                                    }
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            'carLocation2',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <p className="itemValue">
                                                    {inputData.carLocation2 ?? data.carLocation2}
                                                </p>
                                            )}
                                        </div>
                                    ) : data.carLocation2 !== 'No aplica' ? (
                                        <div className="detailItem">
                                            <p className="itemKey">Compañia:</p>
                                            {isEditing ? (
                                                <input
                                                    type="text"
                                                    className="itemValueInput"
                                                    value={
                                                        inputData.carLocation2 ?? data.carLocation2
                                                    }
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            'carLocation2',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <p className="itemValue">
                                                    {inputData.carLocation2 ?? data.carLocation2}
                                                </p>
                                            )}
                                        </div>
                                    ) : null}
                                    {[
                                        { id: 'mechLocation', label: 'Ubicación taller' },
                                        { id: 'mechDate', label: 'Fecha en que se envió a taller' },
                                        { id: 'mechCost', label: 'Costo de arreglo' },
                                        { id: 'mechPaid', label: 'Pagado' },
                                        { id: 'mechToPay', label: 'Por pagar' },
                                        { id: 'mechCostReplacement', label: 'Costo en repuestos' },
                                        { id: 'mechHandPowerCost', label: 'Costo en mano de obra' },
                                    ].map((field) =>
                                        data[field.id] !== 'No aplica' ? (
                                            <div className="detailItem" key={data.id}>
                                                <p className="itemKey">{field.label}:</p>
                                                {isEditing ? (
                                                    <input
                                                        type="text"
                                                        className="itemValueInput"
                                                        value={
                                                            inputData[field.id] ?? data[field.id]
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                field.id,
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                ) : (
                                                    <p className="itemValue">
                                                        {inputData[field.id] ?? data[field.id]}
                                                    </p>
                                                )}
                                            </div>
                                        ) : null
                                    )}
                                    {data.carLocation3 !== 'No aplica' ? (
                                        <div className="detailItem">
                                            <p className="itemKey">Compania:</p>
                                            {isEditing ? (
                                                <input
                                                    type="text"
                                                    className="itemValueInput"
                                                    value={
                                                        inputData.carLocation3 ?? data.carLocation3
                                                    }
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            'carLocation3',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <p className="itemValue">
                                                    {inputData.carLocation3 ?? data.carLocation3}
                                                </p>
                                            )}
                                        </div>
                                    ) : null}
                                    <div className="detailItem">
                                        <p className="itemKey">Documentación:</p>
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                className="itemValueInput"
                                                value={inputData.docs ?? data.docs}
                                                onChange={(e) =>
                                                    handleInputChange('docs', e.target.value)
                                                }
                                            />
                                        ) : (
                                            <p className="itemValue">
                                                {inputData.docs ?? data.docs}
                                            </p>
                                        )}
                                    </div>
                                    <div className="detailItem">
                                        <p className="itemKey">Ubicacion documentación:</p>
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                className="itemValueInput"
                                                value={inputData.docsLocation ?? data.docsLocation}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        'docsLocation',
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        ) : (
                                            <p className="itemValue">
                                                {inputData.docsLocation ?? data.docsLocation}
                                            </p>
                                        )}
                                    </div>
                                    {data.docsLocation2 !== '' &&
                                    data.docsLocation2 !== null &&
                                    data.docsLocation === 'Carpeta Nro...' ? (
                                        <div className="detailItem">
                                            <p className="itemKey">Carpeta número:</p>
                                            {isEditing ? (
                                                <input
                                                    type="text"
                                                    className="itemValueInput"
                                                    value={
                                                        inputData.docsLocation2 ??
                                                        data.docsLocation2
                                                    }
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            'docsLocation2',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <p className="itemValue">
                                                    {inputData.docsLocation2 ?? data.docsLocation2}
                                                </p>
                                            )}
                                        </div>
                                    ) : data.docsLocation2 !== 'No aplica' ? (
                                        <div className="detailItem">
                                            <p className="itemKey">Observaciones (Ubicacion):</p>
                                            {isEditing ? (
                                                <input
                                                    type="text"
                                                    className="itemValueInput"
                                                    value={
                                                        inputData.docsLocation2 ??
                                                        data.docsLocation2
                                                    }
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            'docsLocation2',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <p className="itemValue">
                                                    {inputData.docsLocation2 ?? data.docsLocation2}
                                                </p>
                                            )}
                                        </div>
                                    ) : null}
                                    <div className="detailItem">
                                        <p className="itemKey">Documentación faltante:</p>
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                className="itemValueInput"
                                                value={inputData.missingDocs ?? data.missingDocs}
                                                onChange={(e) =>
                                                    handleInputChange('missingDocs', e.target.value)
                                                }
                                            />
                                        ) : (
                                            <p className="itemValue">
                                                {inputData.missingDocs ?? data.missingDocs}
                                            </p>
                                        )}
                                    </div>
                                    <div className="detailItem">
                                        <p className="itemKey">A cuanto se compro (USD):</p>
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                className="itemValueInput"
                                                value={inputData.purchasedFor ?? data.purchasedFor}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        'purchasedFor',
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        ) : (
                                            <p className="itemValue">
                                                ${inputData.purchasedFor ?? data.purchasedFor}
                                            </p>
                                        )}
                                    </div>
                                    {!isLoading && (
                                        <div>
                                            <div className="detailItem">
                                                <p className="itemKey">Vendido:</p>
                                                {isEditing ? (
                                                    <select
                                                        className="itemValueInput"
                                                        value={inputData.sold ?? data.sold}
                                                        onChange={(e) => {
                                                            handleInputChange(
                                                                'sold',
                                                                e.target.value
                                                            );
                                                            setIsSold(
                                                                e.target.value === 'si'
                                                                    ? true
                                                                    : false
                                                            );
                                                        }}
                                                    >
                                                        <option value="si">si</option>
                                                        <option value="no">no</option>
                                                    </select>
                                                ) : (
                                                    <p className="itemValue">
                                                        {inputData.sold ?? data.sold}
                                                    </p>
                                                )}
                                            </div>
                                            {console.log(isSold)}
                                            {isSold === true ? (
                                                <div className="detailItem">
                                                    <p className="itemKey">Vendido por:</p>
                                                    {isEditing ? (
                                                        <input
                                                            type="text"
                                                            className="itemValueInput"
                                                            value={
                                                                inputData.soldFor ?? data.soldFor
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    'soldFor',
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        <p className="itemValue">
                                                            ${inputData.soldFor ?? data.soldFor}
                                                        </p>
                                                    )}
                                                </div>
                                            ) : (
                                                <div className="detailItem">
                                                    <p className="itemKey">Se va a vender por:</p>
                                                    {isEditing ? (
                                                        <input
                                                            type="text"
                                                            className="itemValueInput"
                                                            value={
                                                                inputData.soldFor ?? data.soldFor
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    'soldFor',
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        <p className="itemValue">
                                                            ${inputData.soldFor ?? data.soldFor}
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="buttonContainer">
                                {isEditing ? (
                                    <button onClick={updateCarData}>Enviar</button>
                                ) : (
                                    <span className="itemValue"></span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DndProvider>
    );
};

export default Single;
