import React from 'react';
import styles from './CarSection.module.css';
import CarImage from '../../assets/carSection.png';
const CarSection = () => {
    return (
        <div className={styles.container}>
            <h3 className={styles.title}>
                El primer paso para tu auto favorito te está esperando
                <span style={{ color: '#F7941D' }}> con nosotros</span>
            </h3>
            <h5 className={styles.subtitle}>
                Todos los vehículos en venta han sido previamente siniestrados.
            </h5>
            <img src={CarImage} alt="Auto" className={styles.img} />
            <div className={styles.bottomDiv} />
        </div>
    );
};

export default CarSection;
