import React from 'react';
import { Drawer, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import styles from './FilterDrawer.module.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import botonBusca from '../../assets/botonbusca.png';

const FilterDrawer = ({
    open,
    onClose,
    selectedBrand,
    handleBrandClick,
    selectedYear,
    handleYearClick,
    selectedMileageRange,
    handleMileageClick,
    handleMinPriceChange,
    handleMaxPriceChange,
    handlePriceSubmit,
    inputMinPrice,
    inputMaxPrice,
    setDrawerOpen,
    brands,
    years,
}) => {
    const handlePriceSubmitAndClose = () => {
        handlePriceSubmit();
        setDrawerOpen(false);
    };
    return (
        <Drawer className={styles.drawerContainer} anchor="bottom" open={open} onClose={onClose}>
            <div className={styles.upSide}>
                <button className={styles.backButton} onClick={onClose}>
                    <ArrowBackIcon />
                </button>
                <h1>Filtrar por</h1>
            </div>

            <div className={styles.drawerContent}>
                <Accordion sx={{ width: '100%', marginTop: '50px', marginBottom: '50px' }}>
                    <AccordionSummary sx={{ width: '100%' }}>
                        <h1>Marca</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul>
                            {brands.map((brand, index) => (
                                <li key={index} onClick={() => handleBrandClick(brand)}>
                                    <p
                                        className={`${styles.liStyle} ${
                                            selectedBrand === brand
                                                ? styles.selected
                                                : styles.notSelected
                                        }`}
                                    >
                                        {brand}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{ width: '100%', marginTop: '50px', marginBottom: '50px' }}>
                    <AccordionSummary sx={{ width: '100%' }}>
                        <h1>Precio</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={styles.preciodiv}>
                            <input
                                className={styles.inputPrecio}
                                type="number"
                                min="0"
                                placeholder="Mínimo"
                                value={inputMinPrice}
                                onChange={handleMinPriceChange}
                            />
                            <span>-</span>
                            <input
                                className={styles.inputPrecio}
                                type="number"
                                min="0"
                                placeholder="Máximo"
                                value={inputMaxPrice}
                                onChange={handleMaxPriceChange}
                            />
                            <button
                                onClick={handlePriceSubmitAndClose}
                                className={styles.botonprecio}
                            >
                                <img
                                    className={styles.img}
                                    src={botonBusca}
                                    alt="botonBusqueda"
                                ></img>
                            </button>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{ width: '100%', marginTop: '50px', marginBottom: '50px' }}>
                    <AccordionSummary sx={{ width: '100%' }}>
                        <h1>Año</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul>
                            {years.map((year, index) => (
                                <li key={index} onClick={() => handleYearClick(year)}>
                                    <p
                                        className={`${styles.liStyle} ${
                                            selectedYear === year
                                                ? styles.selected
                                                : styles.notSelected
                                        }`}
                                    >
                                        {year}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{ width: '100%', marginTop: '50px', marginBottom: '50px' }}>
                    <AccordionSummary sx={{ width: '100%' }}>
                        <h1>Kilometraje</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul>
                            <li key="0" onClick={() => handleMileageClick(0, 0)}>
                                <p
                                    className={
                                        selectedMileageRange &&
                                        selectedMileageRange.min === 0 &&
                                        selectedMileageRange.max === 0
                                            ? styles.selected
                                            : styles.notSelected
                                    }
                                >
                                    0 km
                                </p>
                            </li>
                            <li key="1" onClick={() => handleMileageClick(1, 45000)}>
                                <p
                                    className={
                                        selectedMileageRange &&
                                        selectedMileageRange.min === 1 &&
                                        selectedMileageRange.max === 45000
                                            ? styles.selected
                                            : styles.notSelected
                                    }
                                >
                                    0 a 45.000 km
                                </p>
                            </li>
                            <li key="2" onClick={() => handleMileageClick(45001, 75000)}>
                                <p
                                    className={
                                        selectedMileageRange &&
                                        selectedMileageRange.min === 45001 &&
                                        selectedMileageRange.max === 75000
                                            ? styles.selected
                                            : styles.notSelected
                                    }
                                >
                                    45.000 a 75.000 km
                                </p>
                            </li>
                            <li key="3" onClick={() => handleMileageClick(75001, 100000)}>
                                <p
                                    className={
                                        selectedMileageRange &&
                                        selectedMileageRange.min === 75001 &&
                                        selectedMileageRange.max === 100000
                                            ? styles.selected
                                            : styles.notSelected
                                    }
                                >
                                    75.000 a 100.000 km
                                </p>
                            </li>
                            <li key="4" onClick={() => handleMileageClick(100001, Infinity)}>
                                <p
                                    className={
                                        selectedMileageRange &&
                                        selectedMileageRange.min === 100001 &&
                                        selectedMileageRange.max === Infinity
                                            ? styles.selected
                                            : styles.notSelected
                                    }
                                >
                                    100.000 km o más
                                </p>
                            </li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
            </div>
        </Drawer>
    );
};

export default FilterDrawer;
