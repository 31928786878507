import './datatable.scss';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-activity';
import 'react-activity/dist/library.css';
import { collection, deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import { carColumns } from '../../datatablesource';
import { ref } from 'firebase/storage';
import { getDoc } from 'firebase/firestore';
import { getStorage, deleteObject } from 'firebase/storage';

const textosLocalizados = {
    columnMenuUnsort: 'Sin clasificar',
    columnMenuSortAsc: 'Ordenar de forma ascendente',
    columnMenuSortDesc: 'Ordenar de forma descendente',
    columnMenuFilter: 'Filtrar',
    columnMenuHideColumn: 'Ocultar columna',
    columnMenuShowColumns: 'Mostrar columnas',
    paginationRowsPerPage: 'Filas por página',
    paginationOf: 'de',
    toolbarDensity: 'Densidad',
    toolbarDensityLabel: 'Densidad',
    toolbarDensityCompact: 'Compacta',
    toolbarDensityStandard: 'Estándar',
    toolbarDensityComfortable: 'Cómoda',
    columnMenuButtonLabel: 'Gestionar columnas',
    filterPanelAddFilter: 'Agregar filtro',
    filterPanelDeleteIconLabel: 'Eliminar',
    filterPanelOperators: 'Operadores',
    filterPanelOperatorAnd: 'Y',
    filterPanelOperatorOr: 'O',
    filterPanelColumns: 'Columnas',
    filterPanelInputLabel: 'Valor',
    filterPanelInputPlaceholder: 'Filtrar valor',

    columnMenuTitle: 'Gestionar columnas',
    paginationLabelRowsPerPage: 'Filas por página:',
    paginationLabelDisplayedRows: '{from}-{to} de {count}',
    paginationLabelDisplayedRowsCustom: (count) => `${count} ${count !== 1 ? 'filas' : 'fila'}`,
    paginationLabelFirstPage: 'Primera página',
    paginationLabelLastPage: 'Última página',
    paginationLabelNext: 'Siguiente página',
    paginationLabelPrevious: 'Página anterior',
};

const translateManageColumns = () => {
    const manageColumnsMenuItem = document.querySelector(
        '.MuiDataGrid-columnMenu .MuiDataGrid-menuList .MuiDataGrid-menuItem:last-child'
    );
    if (manageColumnsMenuItem) {
        const manageColumnsText = manageColumnsMenuItem.querySelector('span');
        manageColumnsText.textContent = 'Gestionar columnas';
    }
};

const Datatable = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsub = onSnapshot(
            collection(db, 'cars'),
            async (snapShot) => {
                const list = [];
                for (const doc of snapShot.docs) {
                    const carData = doc.data();
                    const imageUrls = carData;
                    list.push({
                        id: doc.id,
                        images: imageUrls,
                        ...carData,
                    });
                }
                setData(list);
                setLoading(false);
            },
            (error) => {
                console.log(error);
            }
        );
        return () => {
            unsub();
        };
    }, []);

    const handleDelete = async (id) => {
        try {
            const carDoc = doc(db, 'cars', id);
            const carData = (await getDoc(carDoc)).data();
            const imageUrls = carData.images;
            await deleteDoc(carDoc);

            const storage = getStorage();
            const promises = imageUrls.map((url) => deleteObject(ref(storage, url)));
            await Promise.all(promises);

            setData(data.filter((item) => item.id !== id));
        } catch (err) {
            console.log(err);
        }
    };

    const imageColumn = [
        {
            field: 'images',
            headerName: 'Imágenes',
            width: 230,
            renderCell: (params) => {
                return (
                    <div style={{ display: 'flex' }}>
                        {params.value.map((url, index) => {
                            if (index >= 3) return null;
                            return (
                                <img
                                    key={url}
                                    src={url}
                                    alt=""
                                    style={{
                                        objectFit: 'cover',
                                        width: '50px',
                                        aspectRatio: 1,
                                        borderRadius: '50%',
                                        marginRight: '4px',
                                    }}
                                />
                            );
                        })}
                    </div>
                );
            },
        },
    ];

    const actionColumn = [
        {
            field: 'action',
            headerName: 'Acción',
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <Link
                            to={'/admin/users/' + [params.row.id]}
                            style={{ textDecoration: 'none' }}
                        >
                            <div className="viewButton">Ver +</div>
                        </Link>
                        <div className="deleteButton" onClick={() => handleDelete(params.row.id)}>
                            Eliminar
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <div className="datatable">
            <div className="datatableTitle">
                Añadir nuevo auto
                <Link to="/admin/users/new" className="link">
                    Añadir
                </Link>
            </div>
            {loading ? (
                <div className="spinnerContainer">
                    <Spinner color="#f78f20" size={32} />
                </div>
            ) : (
                <DataGrid
                    className="datagrid"
                    rows={data}
                    columns={imageColumn.concat(carColumns, actionColumn)}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    checkboxSelection
                    rowHeight={70}
                    autoHeight={true}
                    localeText={{
                        ...textosLocalizados,
                        paginationRowsPerPage: 'Filas por página',
                    }}
                    onColumnMenuOpen={translateManageColumns}
                />
            )}
        </div>
    );
};

export default Datatable;
