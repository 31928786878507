import React, { useState, useEffect } from 'react';
import styles from './Store.module.css';
import ShoppingCard from '../shoppingCard/ShoppingCard';
import 'react-activity/dist/library.css';
import { collection, query, getDocs, orderBy, startAfter, limit } from 'firebase/firestore';

import { db } from '../../firebase';
import { get } from 'lodash';
import TuneIcon from '@mui/icons-material/Tune';
import FilterDrawer from './FilterDrawer';
import botonBusca from '../../assets/botonbusca.png';

export const Store = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [years, setYears] = useState([]);
    const [brands, setBrands] = useState([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [error, setError] = useState(null);
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [inputMinPrice, setInputMinPrice] = useState('');
    const [inputMaxPrice, setInputMaxPrice] = useState('');
    const [sortOrder, setSortOrder] = useState(null);
    const [lastDoc, setLastDoc] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [hasMoreData, setHasMoreData] = useState(true);

    const handleSortChange = (order) => {
        setSortOrder(order);
    };

    const handleMinPriceChange = (event) => {
        setInputMinPrice(event.target.value);
    };

    const handleMaxPriceChange = (event) => {
        setInputMaxPrice(event.target.value);
    };

    const handlePriceSubmit = () => {
        setMinPrice(Number(inputMinPrice));
        setMaxPrice(Number(inputMaxPrice));
    };

    const handleBrandClick = (brand) => {
        loadMore();

        if (selectedBrand === brand) {
            setSelectedBrand('');
        } else {
            setSelectedBrand(brand);
        }
    };

    const handleYearClick = (year) => {
        loadMore();

        if (selectedYear === year) {
            setSelectedYear('');
        } else {
            setSelectedYear(year);
        }
    };

    const [selectedMileageRange, setSelectedMileageRange] = useState(null);
    const handleMileageClick = (minMileage, maxMileage) => {
        loadMore();

        if (
            selectedMileageRange &&
            selectedMileageRange.min === minMileage &&
            selectedMileageRange.max === maxMileage
        ) {
            setSelectedMileageRange(null);
        } else {
            setSelectedMileageRange({ min: minMileage, max: maxMileage });
        }
    };

    const filterCars = (cars) => {
        let filteredCars = cars.filter((car) => {
            const brandMatch = !selectedBrand || car.brand === selectedBrand;
            const yearMatch = !selectedYear || car.year === selectedYear.toString();
            const mileageMatch =
                !selectedMileageRange ||
                (car.mileage >= selectedMileageRange.min &&
                    car.mileage <= selectedMileageRange.max);
            const priceMatch =
                (!minPrice || parseFloat(car.soldFor) >= minPrice) &&
                (!maxPrice || parseFloat(car.soldFor) <= maxPrice);
            const soldMatch = car.sold === 'no';

            return brandMatch && yearMatch && mileageMatch && priceMatch && soldMatch;
        });

        switch (sortOrder) {
            case 'asc':
                filteredCars.sort((a, b) => parseFloat(a.year) - parseFloat(b.year));
                break;
            case 'desc':
                filteredCars.sort((a, b) => parseFloat(b.year) - parseFloat(a.year));
                break;
            default:
                filteredCars.sort(
                    (a, b) => b.serverTimestamp.toDate() - a.serverTimestamp.toDate()
                );
                break;
        }

        return filteredCars;
    };

    useEffect(() => {
        const getInitialData = async () => {
            const carsRef = collection(db, 'cars');
            const dataQuery = query(carsRef, orderBy('serverTimestamp', 'desc'), limit(9));
            const snapShot = await getDocs(dataQuery);

            if (!snapShot.empty) {
                let list = snapShot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                    images: doc.data().images,
                }));

                setData(list);
                setLastDoc(snapShot.docs[snapShot.docs.length - 1]);

                const brands = [...new Set(list.map((car) => car.brand))];
                setBrands(brands);

                const years = [...new Set(list.map((car) => car.year))];
                setYears(years);
            }
            setLoading(false);
        };

        getInitialData();
        loadMore();
    }, [minPrice, maxPrice]);

    const loadMore = async () => {
        if (lastDoc) {
            const carsRef = collection(db, 'cars');
            const nextQuery = query(
                carsRef,
                orderBy('serverTimestamp', 'desc'),
                startAfter(lastDoc),
                limit(9)
            );
            const snapShot = await getDocs(nextQuery);

            if (!snapShot.empty) {
                let moreData = snapShot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                    images: doc.data().images,
                }));

                setData((prevData) => [...prevData, ...moreData]);
                setLastDoc(snapShot.docs[snapShot.docs.length - 1]);
            } else {
                setHasMoreData(false);
            }
        }
    };

    if (error) {
        return <div>Error al cargar los datos: {error.message}</div>;
    }

    return (
        <div className={styles.container}>
            <div className={styles.filterContainer}>
                <h3>Kilometro</h3>
                <ul>
                    <li onClick={() => handleMileageClick(0, 0)}>
                        <p
                            className={
                                selectedMileageRange &&
                                selectedMileageRange.min === 0 &&
                                selectedMileageRange.max === 0
                                    ? styles.selected
                                    : styles.notSelected
                            }
                        >
                            0 km
                        </p>
                    </li>
                    <li onClick={() => handleMileageClick(1, 45000)}>
                        <p
                            className={
                                selectedMileageRange &&
                                selectedMileageRange.min === 1 &&
                                selectedMileageRange.max === 45000
                                    ? styles.selected
                                    : styles.notSelected
                            }
                        >
                            0 a 45.000 km
                        </p>
                    </li>
                    <li onClick={() => handleMileageClick(45001, 75000)}>
                        <p
                            className={
                                selectedMileageRange &&
                                selectedMileageRange.min === 45001 &&
                                selectedMileageRange.max === 75000
                                    ? styles.selected
                                    : styles.notSelected
                            }
                        >
                            45.000 a 75.000 km
                        </p>
                    </li>
                    <li onClick={() => handleMileageClick(75001, 100000)}>
                        <p
                            className={
                                selectedMileageRange &&
                                selectedMileageRange.min === 75001 &&
                                selectedMileageRange.max === 100000
                                    ? styles.selected
                                    : styles.notSelected
                            }
                        >
                            75.000 a 100.000 km
                        </p>
                    </li>
                    <li onClick={() => handleMileageClick(100001, Infinity)}>
                        <p
                            className={
                                selectedMileageRange &&
                                selectedMileageRange.min === 100001 &&
                                selectedMileageRange.max === Infinity
                                    ? styles.selected
                                    : styles.notSelected
                            }
                        >
                            100.000 km o más
                        </p>
                    </li>
                </ul>

                <h3>Marca</h3>
                <ul>
                    {brands.map((brand, index) => (
                        <li key={index} onClick={() => handleBrandClick(brand)}>
                            <p
                                className={
                                    selectedBrand === brand ? styles.selected : styles.notSelected
                                }
                            >
                                {brand}
                            </p>
                        </li>
                    ))}
                </ul>
                <h3>Precio</h3>
                <div className={styles.preciodiv}>
                    <input
                        className={styles.inputPrecio}
                        type="number"
                        min="0"
                        placeholder="Mínimo"
                        value={inputMinPrice}
                        onChange={handleMinPriceChange}
                    />
                    -
                    <input
                        className={styles.inputPrecio}
                        type="number"
                        min="0"
                        placeholder="Máximo"
                        value={inputMaxPrice}
                        onChange={handleMaxPriceChange}
                    />
                    <button onClick={handlePriceSubmit} className={styles.botonprecio}>
                        <img className={styles.img} src={botonBusca} alt="botonBusqueda"></img>
                    </button>
                </div>

                <h3>Año</h3>
                <ul>
                    {years.map((year, index) => (
                        <li key={index} onClick={() => handleYearClick(year)}>
                            <p
                                className={
                                    selectedYear === year ? styles.selected : styles.notSelected
                                }
                            >
                                {year}
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={styles.filterContainerMobile}>
                <button className={styles.filterButton} onClick={() => setDrawerOpen(true)}>
                    <TuneIcon /> <p>Filtrar</p>
                </button>

                <div className={styles.filterButtonOrdenar}>
                    <select onChange={(e) => handleSortChange(e.target.value)}>
                        <option value="">Ordenar por valor</option>
                        <option value="asc">Menor a Mayor</option>
                        <option value="desc">Mayor a Menor</option>
                    </select>
                </div>
            </div>

            <div className={styles.cardsAndButtonContainer}>
                <div className={styles.cards}>
                    {filterCars(data).map((car) => (
                        <ShoppingCard
                            key={car.id}
                            id={car.id}
                            size="Size"
                            price={car.soldFor ? car.soldFor : 'N/A'}
                            brand={car.brand ? car.brand : 'N/A'}
                            year={car.year ? car.year : 'N/A'}
                            mileage={car.mileage ? car.mileage : 'N/A'}
                            imageUrl={get(car, 'images[0]', 'fallbackImageUrl')}
                        />
                    ))}
                </div>
                {hasMore && (
                    <button
                        className={`${styles.loadMore} ${!hasMoreData ? styles.noMoreData : ''}`}
                        onClick={loadMore}
                        disabled={!hasMoreData}
                    >
                        <p>Cargar más</p>
                    </button>
                )}
            </div>

            <div className={styles.cardsMobile}>
                {filterCars(data).map((car) => (
                    <ShoppingCard
                        key={car.id}
                        id={car.id}
                        size="Mobile"
                        price={car.soldFor ? car.soldFor : 'N/A'}
                        brand={car.brand ? car.brand : 'N/A'}
                        year={car.year ? car.year : 'N/A'}
                        mileage={car.mileage ? car.mileage : 'N/A'}
                        imageUrl={get(car, 'images[0]', 'fallbackImageUrl')}
                    />
                ))}
                {hasMore && (
                    <button
                        className={`${styles.loadMoreMobile} ${
                            !hasMoreData ? styles.noMoreDataMobile : ''
                        }`}
                        onClick={loadMore}
                        disabled={!hasMoreData}
                    >
                        <p>Cargar más</p>
                    </button>
                )}
            </div>

            <FilterDrawer
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                selectedBrand={selectedBrand}
                setSelectedBrand={setSelectedBrand}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
                selectedMileageRange={selectedMileageRange}
                setSelectedMileageRange={setSelectedMileageRange}
                brands={brands}
                years={years}
                handleBrandClick={handleBrandClick}
                handleYearClick={handleYearClick}
                handleMileageClick={handleMileageClick}
                handleMinPriceChange={handleMinPriceChange}
                handleMaxPriceChange={handleMaxPriceChange}
                handlePriceSubmit={handlePriceSubmit}
                inputMinPrice={inputMinPrice}
                inputMaxPrice={inputMaxPrice}
                setDrawerOpen={setDrawerOpen}
            />
        </div>
    );
};
