import React from 'react';
import styles from '.././styles/HomePage/Homepage.module.css';
import Cards from '../components/cards/Cards';
import CarSection from '../components/carSection/CarSection';
import { HeroSection } from '../components/HomePage/HeroSection';
import { Ingresos } from '../components/HomePage/Ingresos';
import Layout from '../components/layout/Layout';
import { AboutUs } from '../components/HomePage/AboutUs';

export function HomePage() {
    return (
        <Layout>
            <div className={styles.container}>
                <HeroSection />
                <Cards />
                <Ingresos />
                <AboutUs />
                <CarSection />
            </div>
        </Layout>
    );
}
