import React from 'react';
import styles from './ShoppingCard.module.css';
import { useNavigate } from 'react-router-dom';

function ShoppingCard({ id, size, price, brand, year, mileage, imageUrl }) {
    const navigate = useNavigate();

    const sizeModifier = size ? size : '';

    const getClassName = (baseClass) => {
        return sizeModifier
            ? `${styles[baseClass]} ${styles[baseClass + sizeModifier]}`
            : styles[baseClass];
    };

    return (
        <div
            className={getClassName('container')}
            onClick={
                sizeModifier === 'Mobile' ? () => navigate(`/singlecarconsult/${id}`) : undefined
            }
        >
            <div className={getClassName('imgContainer')}>
                <img
                    src={imageUrl ? imageUrl : 'fallbackImageUrl'}
                    className={getClassName('imagenMuestra')}
                    alt="Auto"
                />
            </div>
            <div className={getClassName('textContainer')}>
                <h4>{brand}</h4>
                <h5>
                    {year} - {mileage} KM
                </h5>
                <h2>${price}</h2>

                <button
                    className={getClassName('consultButton')}
                    onClick={() => navigate(`/singlecarconsult/${id}`)}
                >
                    <p>Consultar</p>
                </button>
            </div>
        </div>
    );
}

export default ShoppingCard;
