import { React, useState, useEffect } from 'react';
import styles from '../../styles/ConsultedCar/MainSection.module.css';
import { useParams } from 'react-router-dom';
import Carousel, { consts } from 'react-elastic-carousel';
import { IoIosArrowBack } from 'react-icons/io';
import { IoIosArrowForward } from 'react-icons/io';
import ShoppingCard from '../shoppingCard/ShoppingCard';
import Gallery from '../gallery/Gallery';
import { get } from 'lodash';
import { Link } from 'react-router-dom';

import { doc, getDoc, collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';

export default function MainSection() {
    const { carid } = useParams();
    const [selectedImage, setSelectedImage] = useState('');
    const [data, setData] = useState({ images: [] });
    const [width, setWindowWidth] = useState(0);
    const [datar, setDatar] = useState([]);

    const handleConsultClick = () => {
        const defaultMessage = `Hola, estoy interesado en el ${data.brand} ${data.model} ${data.version} del año ${data.year}. ¿Podrías ayudarme? http://agenciapaternalmotor.com.ar/singlecarconsult/${carid}`;
        const encodedMessage = encodeURIComponent(defaultMessage);
        window.open(
            `https://api.whatsapp.com/send?phone=5491128954906&text=${encodedMessage}`,
            '_blank'
        );
    };

    const handleImageClick = (imgUrl) => {
        setSelectedImage(imgUrl);
    };

    function myArrow({ type, onClick, isEdge }) {
        const pointer =
            type === consts.PREV ? (
                <div className={styles.arrowContainer}>
                    <div className={styles.arrows}> {<IoIosArrowBack />} </div>
                </div>
            ) : (
                <div className={styles.arrowContainer}>
                    <div className={styles.arrows}>{<IoIosArrowForward />}</div>
                </div>
            );
        return (
            <div onClick={onClick} disabled={isEdge}>
                {pointer}
            </div>
        );
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const docRef = doc(db, 'cars', carid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    console.log('Document data:', docSnap.data());
                } else {
                    console.log('No such document!');
                }
                setData(docSnap.data());
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, [carid]);

    useEffect(() => {
        const carsRef = collection(db, 'cars');
        const q = query(carsRef, orderBy('recommended', 'desc'));

        const _compareRec = (a, b) => {
            return parseInt(b.recommended) - parseInt(a.recommended);
        };

        const unsub = onSnapshot(
            q,
            async (snapShot) => {
                const list = [];
                for (const doc of snapShot.docs) {
                    const carData = doc.data();
                    list.push({
                        id: doc.id,
                        ...carData,
                    });
                }

                list.sort(_compareRec);
                setDatar(list);
            },
            (error) => {
                console.log(error);
            }
        );

        const updateDimensions = () => {
            const width = window.innerWidth;
            setWindowWidth(width);
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => {
            unsub();
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.insideDiv}>
                <div className={styles.fotosDiv}>
                    <img
                        src={selectedImage || data.images[0]}
                        alt=""
                        className={styles.itemLarge}
                    />
                    <div className={styles.itemImgWrapper}>
                        <Carousel
                            enableSwipe={true}
                            enableMouseSwipe={true}
                            itemsToShow={4}
                            itemsToScroll={1}
                            pagination={false}
                            renderArrow={myArrow}
                        >
                            {data.images.map((image) => (
                                <img
                                    key={image}
                                    src={image}
                                    alt=""
                                    className={styles.itemImga}
                                    onClick={() => handleImageClick(image)}
                                />
                            ))}
                        </Carousel>
                    </div>
                </div>
                <div className={styles.textArea}>
                    <h1>{data.brand}</h1>
                    <h3>
                        {data.version}
                        {' - '} {data.model}
                    </h3>
                    <div className={styles.lineInside}>
                        <h5>Año:</h5>
                        <p>{data.year}</p>
                    </div>
                    <div className={styles.lineInside}>
                        <h5>Kilometraje:</h5>
                        <p>{data.mileage}</p>
                    </div>
                    <div className={styles.lineInside}>
                        <h5>Condición:</h5>
                        <p>{data.gObservations}</p>
                    </div>
                    <h2>${data.soldFor}</h2>
                    <button className={styles.consultbutton} onClick={handleConsultClick}>
                        <p>Consultar</p>
                    </button>
                </div>

                <div className={styles.textAreaMobile}>
                    <h1>{data.brand}</h1>
                    <h3>
                        {data.version}
                        {' - '} {data.model}
                    </h3>
                    <h2>${data.soldFor}</h2>
                    <button className={styles.consultbutton} onClick={handleConsultClick}>
                        <p>Consultar</p>
                    </button>
                    <div className={styles.lineInside}>
                        <h5>Año:</h5>
                        <p>{data.year}</p>
                    </div>
                    <div className={styles.lineInside}>
                        <h5>Kilometraje:</h5>
                        <p>{data.mileage}</p>
                    </div>
                    <div className={styles.lineInside}>
                        <h5>Condición:</h5>
                        <p>{data.gObservations}</p>
                    </div>
                </div>
            </div>

            <div className={styles.fichaTecnica}>
                <h1>Ficha Técnica</h1>
                <div className={styles.fichaInside}>
                    <div className={styles.leftFicha}>
                        <div className={styles.categoriasLeft}>
                            <ul>
                                <li>
                                    <h2>Marca:</h2>
                                </li>
                                <li>
                                    <h2>Modelo:</h2>
                                </li>
                                <li>
                                    <h2>Año:</h2>
                                </li>
                                <li>
                                    <h2>Version:</h2>
                                </li>
                                <li>
                                    <h2>Kilometraje:</h2>
                                </li>
                            </ul>
                        </div>
                        <div className={styles.categoriasRight}>
                            <ul>
                                <li>
                                    <p>{data.brand}</p>
                                </li>
                                <li>
                                    <p>{data.model}</p>
                                </li>
                                <li>
                                    <p>{data.year}</p>
                                </li>
                                <li>
                                    <p>{data.version}</p>
                                </li>
                                <li>
                                    <p>{data.mileage}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.cabecera}>
                <h3> Autos recomendados </h3>
                <Link to="/used" style={{ textDecoration: 'none' }}>
                    <p>Ver todos</p>
                </Link>
            </div>
            <div className={styles.cartas}>
                {width > 1440 ? (
                    datar
                        .slice(0, 4)
                        .map((car) => (
                            <ShoppingCard
                                key={car.id}
                                id={car.id}
                                size="Size"
                                price={car.soldFor ? car.soldFor : 'N/A'}
                                brand={car.brand ? car.brand : 'N/A'}
                                year={car.year ? car.year : 'N/A'}
                                mileage={car.mileage ? car.mileage : 'N/A'}
                                imageUrl={get(car, 'images[0]', 'fallbackImageUrl')}
                            />
                        ))
                ) : width > 1280 ? (
                    datar
                        .slice(0, 3)
                        .map((car) => (
                            <ShoppingCard
                                key={car.id}
                                id={car.id}
                                size="Size"
                                price={car.soldFor ? car.soldFor : 'N/A'}
                                brand={car.brand ? car.brand : 'N/A'}
                                year={car.year ? car.year : 'N/A'}
                                mileage={car.mileage ? car.mileage : 'N/A'}
                                imageUrl={get(car, 'images[0]', 'fallbackImageUrl')}
                            />
                        ))
                ) : width > 768 ? (
                    datar
                        .slice(0, 2)
                        .map((car) => (
                            <ShoppingCard
                                key={car.id}
                                id={car.id}
                                size="Size"
                                price={car.soldFor ? car.soldFor : 'N/A'}
                                brand={car.brand ? car.brand : 'N/A'}
                                year={car.year ? car.year : 'N/A'}
                                mileage={car.mileage ? car.mileage : 'N/A'}
                                imageUrl={get(car, 'images[0]', 'fallbackImageUrl')}
                            />
                        ))
                ) : (
                    <div className={styles.galleryContainer}>
                        <Gallery />
                    </div>
                )}
            </div>
        </div>
    );
}
