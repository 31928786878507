import React, { useEffect, useState } from 'react';
import styles from './Gallery.module.css';
import Carousel, { consts } from 'react-elastic-carousel';
import { collection, onSnapshot, query, orderBy, where, limit } from 'firebase/firestore';
import { db } from '../../firebase';
import { get } from 'lodash';

import ShoppingCard from '../shoppingCard/ShoppingCard';

function noArrow({ type, onClick, isEdge }) {
    const pointer =
        type === consts.PREV ? (
            <div className={styles.arrowContainer}>
                <div></div>
            </div>
        ) : (
            <div className={styles.arrowContainer}>
                <div></div>
            </div>
        );
    return (
        <div onClick={onClick} disabled={isEdge}>
            {pointer}
        </div>
    );
}

export default function Gallery(props) {
    const { ordenIngreso } = props;

    const [width, setWindowWidth] = useState(0);

    const [data, setData] = useState([]);

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        const carsRef = collection(db, 'cars');
        let q;

        if (ordenIngreso) {
            q = query(
                carsRef,
                where('sold', '==', 'no'),
                orderBy('serverTimestamp', 'desc'),
                limit(5)
            );
        } else {
            q = query(collection(db, 'cars'), limit(5));
        }

        const unsub = onSnapshot(
            q,
            async (snapShot) => {
                const list = [];
                for (const doc of snapShot.docs) {
                    const carData = doc.data();
                    const imageUrls = carData;
                    list.push({
                        id: doc.id,
                        images: imageUrls,
                        ...carData,
                    });
                }
                setData(list);
            },
            (error) => {
                console.log(error);
            }
        );

        return () => {
            window.removeEventListener('resize', updateDimensions);
            unsub();
        };
    }, [ordenIngreso]);

    const updateDimensions = () => {
        const width = window.innerWidth;
        setWindowWidth(width);
    };

    return (
        <div className={styles.container}>
            <Carousel
                enableSwipe={true}
                enableMouseSwipe={true}
                pagination={false}
                itemsToShow={width <= 768 ? 1 : 3}
                enableAutoPlay={true}
                itemsToScroll={width <= 768 ? 1 : 3}
                renderArrow={noArrow}
            >
                {data.slice(0, 3).map((car) => (
                    <ShoppingCard
                        key={car.id}
                        id={car.id}
                        size="Size"
                        price={car.soldFor ? car.soldFor : 'N/A'}
                        brand={car.brand ? car.brand : 'N/A'}
                        year={car.year ? car.year : 'N/A'}
                        mileage={car.mileage ? car.mileage : 'N/A'}
                        imageUrl={get(car, 'images[0]', 'fallbackImageUrl')}
                    />
                ))}
            </Carousel>
        </div>
    );
}
