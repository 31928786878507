import React from 'react';
import styles from '../../styles/Sell/HeroSection.module.css';
import key from '../../assets/llave.png';
import motor from '../../assets/motor-de-busqueda-web.png';
import billete from '../../assets/billete-de-banco.png';
import conferencia from '../../assets/conferencia-online.png';

export const HeroSection = () => {
    return (
        <div className={styles.container}>
            <div className={styles.insideContainer}>
                <div className={styles.cuadroPrincipal}>
                    <img className={styles.keyImage} src={key} alt="llave"></img>
                    <h1 className={styles.title}>
                        ¿Buscas <span style={{ color: '#F7941D' }}> vender </span>
                        tu auto?
                    </h1>
                    <h5 className={styles.subtitle}>
                        Paternal Motor se encarga de cotizar y comprarlo
                    </h5>
                </div>
                <div className={styles.cartas}>
                    <div className={styles.cardContainer}>
                        <div className={styles.imgContainer}>
                            <img src={motor} className={styles.img1} alt="Motor" />
                        </div>
                        <div className={styles.textContainer}>
                            <h2 className={styles.cardTitle}>Verificación profesional</h2>
                            <p>
                                Realizamos una verificación técnica del vehículo en nuestros
                                predios. La inspección cuenta con un peritaje de 280 puntos del
                                vehículo. Con el resultado completo de la verificación te haremos la
                                oferta final para la compra de tu vehículo.
                            </p>
                        </div>
                    </div>
                    <div className={styles.cardContainer}>
                        <div className={styles.imgContainer}>
                            <img src={billete} className={styles.img1} alt="Billete" />
                        </div>
                        <div className={styles.textContainer}>
                            <h2 className={styles.cardTitle}>Obtén una cotización Online</h2>
                            <p>
                                En nuestra página web, podrás obtener una cotización justa para tu
                                auto usado en cuestión de minutos. Simplemente ingresa la
                                información básica de tu vehículo, como la marca, modelo, año y
                                kilometraje, y obtén una oferta en tiempo real.
                            </p>
                        </div>
                    </div>
                    <div className={styles.cardContainer}>
                        <div className={styles.imgContainer}>
                            <img src={conferencia} className={styles.img1} alt="Conferencia" />
                        </div>
                        <div className={styles.textContainer}>
                            <h2 className={styles.cardTitle}>Gestión de transferencia</h2>
                            <p>
                                Para asegurar y garantizar todo el proceso de compra y venta de
                                automóviles, en Paternal Motor tenemos: una escribanía propia que te
                                garantizará toda la documentación de los vehículos y una sucursal
                                bancaria del Santander .
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
