export const carColumns = [
    { field: 'licencePlate', headerName: 'Patente', width: 120 },
    { field: 'brand', headerName: 'Marca', width: 150 },
    { field: 'model', headerName: 'Modelo', width: 150 },
    {
        field: 'year',
        headerName: 'Año',
        width: 150,
        sortComparator: (v1, v2) => parseInt(v1, 10) - parseInt(v2, 10),
    },

    {
        field: 'mileage',
        headerName: 'Kilometraje',
        width: 150,
        valueGetter: (params) => parseInt(params.value, 10),
        sortComparator: (v1, v2, param1, param2) =>
            param1.api.getCellValue(param1.id, 'mileage') -
            param2.api.getCellValue(param2.id, 'mileage'),
    },
];
